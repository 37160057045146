<template>
  <div v-show="authenticated" class="content-wrapper">
    <div class="title my-4 text-center">
        ลงทะเบียนร่วมสนุกกิจกรรม
    </div>
    <form id="requestSmsForm" action class="needs-validation" novalidate>
        <!-- tel -->
        <label for="tel">เบอร์โทรศัพท์</label>
        <div class="row">
            <div class="col-8 pr-1">
                <div class="form-group">
                    <input
                    type="tel"
                    id="tel"
                    name="phoneNumber"
                    class="form-control"
                    placeholder="0999999999"
                    pattern="[0]{1}[0-9]{9}"
                    maxlength="10"
                    required
                    v-model="phone"
                    />
                    <div class="refOtp pt-0" v-if="refOtp">Ref: {{ refOtp }}</div>
                    <div class="invalid-feedback">ต้องเป็นตัวเลข 10 หลัก</div>
                </div>
            </div>
            <div class="col-4 pl-1">
                <button
                    class="btn btn-outline-primary w-100"
                    type="submit"
                    id="send_otp"
                    :disabled="statusOtp != 'none'"
                    data-tag="send_otp"
                >
                    <span v-if="statusOtp == 'none'">ส่ง OTP</span>
                    <span v-if="statusOtp == 'wait'" class="wait"></span>
                    <span v-if="statusOtp == 'timeout'" style="color: black"
                    >{{ timeout }}s</span
                    >
                </button>
            </div>
        </div>
    </form>
    <form
        v-if="sentOtp"
        id="enterOtpForm"
        action
        class="needs-validation"
        novalidate
        >
            <!-- otp code input-->
            <div class="form-group">
                <label for="otpCode">รหัส OTP</label>
                <input
                type="tel"
                id="otpCodeInput"
                name="otp"
                class="form-control"
                pattern="[0-9]{6}"
                maxlength="6"
                required
                placeholder="123456"
                />
                <div class="invalid-feedback">OTP ควรใช้ตัวเลข 6 หลัก</div>
            </div>

            <!-- process button -->
            <button
                id="processOkBtn"
                type="submit"
                style="width: 100%"
                class="btn btn-primary mt-5"
                data-tag="ok"
            >
                ต่อไป
            </button>
        </form>
  </div>
</template>

<script>

export default {
    name: "ssclick-register",
    props: ["shopcode"],
    data() {
        return {
            authenticated:false,
            statusOtp: "none",
            timeout: 0,
            setTimeout: null,
            sentOtp: false,
            clickOtp: false,
            refOtp: "",
            phoneOtp:"",
            phone: null,
            otpValidAddlis: false,

        };
    },
    computed: {
        userId(){
            return this.$store.state.user_line.userId
        }
    },
    methods: {
        initValidRequestSmsForm() {
            const form = document.getElementById("requestSmsForm");
            // Loop over them and prevent submission
            form.addEventListener(
                "submit",
                (e) => {
                e.preventDefault();
                e.stopPropagation();

                if (form.checkValidity() !== false) {
                    // when form validated
                    this.sendOtpSms(form);
                }
                form.classList.add("was-validated");
                },
                false
            );
        },
        initValidEnterOtpForm() {
            const form = document.getElementById("enterOtpForm");
            // Loop over them and prevent submission
            form.addEventListener(
                "submit",
                (e) => {
                e.preventDefault();
                e.stopPropagation();

                if (form.checkValidity() !== false) {
                    // when form validated
                    this.postOtpValidate(form);
                }
                form.classList.add("was-validated");
                },
                false
            );
            },
            sendSmsAgain() {},
            // set timeout click request sms otp
            async setTimeoutOtp() {
            this.statusOtp = "timeout";
            this.timeout = 30;
            this.setTimeout = setInterval(() => {
                this.timeout -= 1;
            }, 1000);
        },
        async auth() {    
            const headers = new Headers({
                "Content-Type": "application/json",
                "X-Line-Account-ID":this.$userLine?.profile?.userId,
            });

            const res = await fetch(process.env.VUE_APP_API_URL + "/../ssclick/auth",{
                method: "GET",
                headers:headers
            });
    
            window.loading(false);

            if(res.status === 200 && this.shopcode =="noshop"){
                this.$router.replace(`/ssclick/noshop/register-success`);
                return;
            }else if(res.status === 200){
                this.$router.replace(`/ssclick/${this.shopcode}/campaign`);
                return;
            }

            if(this.$route.query.consented==null){
                const liff_consent = process.env.VUE_APP_LIFF_CONSENT;
                const liff_root = process.env.VUE_APP_LIFF_ROOT;
                window.location = `https://liff.line.me/${liff_consent}?targetUrl=https%3A%2F%2Fliff.line.me%2F${liff_root}%2Fssclick%2F${this.shopcode}%2Fregister%3Fconsented%3Dtrue`
                return;
            }
            
            this.authenticated = true
        },

        async postSendOtpSms() {
            const reqBody = {
                phoneNumber: this.phone,
                serviceName: "SSClick",
                // shopCode: this.$store.state.storeId,
            };

            const headers = new Headers({
                "Content-Type": "application/json",
                "X-Line-Account-ID":this.$userLine?.profile?.userId,
            });

            return await fetch(process.env.VUE_APP_API_URL + "/../ssclick/otp", {
                method: "POST",
                headers: headers,
                body: JSON.stringify(reqBody),
            });
        },
        async sendOtpSms(form) {
            this.statusOtp = "wait";
            this.sentOtp = false;
            try {
                const res = await this.postSendOtpSms(form);
                console.log("postSendOtpSms status:", res.status);
                // check status for redirect some page or set params
                let body = [];
                switch (res.status) {
                case 201:
                    this.sentOtp = true;
                    this.$nextTick(() => {
                    // check has been addEventListener before ?
                    if (!this.otpValidAddlis) {
                        this.initValidEnterOtpForm();
                        this.otpValidAddlis = true;
                    }
                    });
                    body = await res.json();
                    this.phoneOtp = this.phone;
                    this.refOtp = body.otpRefCode;
                    //form.classList.remove("was-validated");
                    this.setTimeoutOtp();
                    break;
                case 409:
                    this.statusOtp = "none";
                    this.alertError("DUPLICATED_PHONENUMBER");
                    break;
                case 422:
                    this.statusOtp = "none";
                    body = await res.json();
                    // if in case give error
                    this.alertError(body.code);
                    break;
                default:
                    this.statusOtp = "none";
                    console.log("no case:");
                    this.alertError();
                }
            } catch (error) {
                console.log(error);
                window.$pushEvent("otp", "error", error);
                this.statusOtp = "none";
                window.loading(false);
                this.alertError();
            } finally {
                window.loading(false);
            }
        },
        async postOtpValidate(form) {
            window.loading(true);

            const formData = new FormData(form);
            formData.append("otpRefCode", this.refOtp);
            formData.append("phoneNumber", this.phoneOtp);

            return await fetch(process.env.VUE_APP_API_URL + "/../ssclick/register", {
                method: "POST",
                headers: {
                    "X-Line-Account-ID":this.$userLine?.profile?.userId,
                },
                body: formData,
            })
            .then((res) => {
                console.log("postOtpValidate status", res.status);
                window.loading(false);

                // check status code for do someting
                if (res.status == 200) {
                    this.$router.replace(`/ssclick/${this.shopcode}/register-success`);
                    return;
                }

                if (res.status == 422) {
                    this.Swal.fire(
                    "ขออภัย",
                    "รหัส OTP หรือข้อมูลไม่ถูกต้อง กรุณาลองใหม่ภายหลัง",
                    "warning"
                    );
                    return;
                }

                // if in case give error
                console.log("status no case", res);
                this.$Error();
            })
            .catch((error) => {
                console.log(error);
                window.loading(false);
                this.$Error();
            });
        },
        alertError(code) {
            switch (code) {
                case "INVALID_PHONENUMBER":
                    this.Swal.fire(
                        "เบอร์โทรศัพท์ไม่ถูกต้อง",
                        "กรุณาลองใหม่ มีข้อสงสัยกรุณาติดต่อเจ้าหน้าที่",
                        "error"
                    );
                    break;
                case "DUPLICATED_PHONENUMBER":
                    this.Swal.fire(
                        "ไม่สามารถลงทะเบียนได้ เนื่องจากเบอร์โทรนี้ลงทะเบียนแล้ว",
                        "กรุณาลองใหม่ มีข้อสงสัยกรุณาติดต่อเจ้าหน้าที่",
                        "error"
                    );
                    break;
                default:
                    this.Swal.fire("กรุณาส่งรหัส OTP อีกครั้ง", "", "warning");
            }
        },
    },
    watch: {
        // change stage when timeout
        timeout(val) {
            if (val <= 0) {
                clearInterval(this.setTimeout);
                this.statusOtp = "none";
            }
        },
    },
    beforeMount(){
        window.loading(true);
        this.auth();
    },
    mounted() {
        this.initValidRequestSmsForm();
    },
};
</script>

<style scoped>
    .title {
        font-size: 25px;
        font-weight:bold;
    }
        
    .otp {
        color: #2599d6;
    }

    #pinInput {
        letter-spacing: 0.9em;
        text-align: center;
        text-indent: 0.9em;
        font-size: 18px;
        -webkit-text-security: disc;
        -webkit-text-stroke-width: 0.2em;
    }

    .form-control {
    text-align: center;
    letter-spacing: 0.2rem;
    }

    #otpCodeInput {
    letter-spacing: 0.8rem;
    }

    .wait:after {
    text-indent: 10em;
    margin-right: 10px;
    content: "\2022";
    animation: dots 1s steps(5, end) infinite;
    }

    @keyframes dots {
    0%,
    20% {
        color: rgba(255, 255, 255, 0);
        text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
        0.5em 0 0 rgba(255, 255, 255, 0);
    }

    40% {
        color: rgb(0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(255, 255, 255, 0),
        0.5em 0 0 rgba(255, 255, 255, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgba(255, 255, 255, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 rgb(0, 0, 0), 0.5em 0 0 rgb(0, 0, 0);
    }
    }
    .btn-outline-primary.disabled,
    .btn-outline-primary:disabled {
    color: #e8e8e8;
    background-color: #c1c1c1;
    border-color: #c3c3c3;
    }

    .refOtp {
    color: cadetblue;
    }

    .content-wrapper{
        background-color: rgba(255, 255, 255, 0.85);
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 12px;
        flex: 1;
    }
</style>
